<template>
  <div class="login-page">
    <header-tab></header-tab>
    <div class="login-outter">
      <div class="login-inner">
        <div class="login-d1">
          <div
            class="ss"
            align="center"
            v-for="(item,index) in typeBtns"
            :key="index"
            @click="handleChange(index)"
          >
            <a :class="activeIndex === index ? 'alineActive' : ''">{{item.label}}</a>
            <div :class="activeIndex === index ? 'aline alineBg' : 'aline'"></div>
          </div>
        </div>
        <div
          class="login-form-item"
          style="margin-top: 32px"
        >
          <img
            class="login-form-img"
            src="../../assets/images/iphone.png"
          />
          <input
            class="login-input"
            autocomplete="on"
            v-model="form.iphone"
            :placeholder="placeText"
          />
        </div>
        <div
          class="login-form-item"
          style="margin-top: 20px"
        >
          <img
            class="login-form-img"
            src="../../assets/images/key.png"
          />
          <input
            class="login-input"
            autocomplete="on"
            :type="typeName"
            v-model="form.password"
            placeholder="请输入密码"
          />
          <img
            class="login-form-img2"
            @click="handleChangKey"
            v-lazy="showPwd ? require('../../assets/images/eyes.png') : require('../../assets/images/meimao.png') "
          />
        </div>
        <div
          align="center"
          style="margin-top: 22px"
        >
          <el-button
            type="primary"
            class="login-btn"
            size="medium"
            @click="handleSubmit"
          >登 录</el-button>
        </div>
      </div>
    </div>

    <nav-footer></nav-footer>
  </div>
</template>

<script>
export default {
  name: "login",
  components: {
    "header-tab": () => import("@/components/headerTab"),
    "nav-footer": () => import("@/components/navFooter"),
  },
  data() {
    return {
      activeIndex: 0,
      placeText: "请输入账号",
      typeBtns: [
        {
          label: "商家登录",
        },
        {
          label: "用户登录",
        },
      ],
      showPwd: false,
      typeName: "password",
      form: {
        iphone: "",
        password: "",
      },
    };
  },
  methods: {
    handleChange(n) {
      this.activeIndex = n;
      this.placeText = this.activeIndex ? "请输入手机号" : "请输入账号";
    },
    handleChangKey() {
      this.showPwd = !this.showPwd;
      this.typeName = this.showPwd ? "" : "password";
    },
    handleSubmit() {
      this.$message.error("输入账号密码错误");
    },
  },
};
</script>

<style lang="less" scoped>
.login-page {
  width: 100%;
  height: 100%;
}
.login-outter {
  position: relative;
  border-top: 1px solid #fff;
  margin-top: 60px;
  height: calc(100% - 60px);
  min-height: 400px;
  min-width: 600px;
  background-image: url("../../assets/images/loginBg.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.login-inner {
  position: absolute;
  width: 450px;
  height: 330px;
  background: #fff;
  border-radius: 4px;
  top: 150px;
  right: 150px;
}
.login-d1 {
  display: flex;
  justify-content: flex-start;
  margin-top: 42px;
  .ss {
    font-size: 16px;
    color: #878687;
    width: 50%;
    text-align: center;
    cursor: pointer;
    a {
      display: block;
    }
    .alineActive {
      color: #1890ff;
    }
    .aline {
      width: 40px;
      height: 3px;
      margin: 0 auto;
      margin-top: 10px;
    }
    .alineBg {
      background: #1890ff;
    }
  }
}
.login-form-item {
  display: flex;
  justify-content: space-between;
  width: 310px;
  padding: 0 10px;
  height: 40px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  align-items: center;
  margin: 0 auto;
}
.login-form-img {
  width: 16px;
  height: 16px;
}
.login-form-img2 {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.login-input {
  border-style: none;
  outline: none;
  padding: 10px;
  font-size: 15px;
  width: 90%;
}
.login-btn {
  width: 240px;
}
</style>